import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withTheme from '../theme';
import { Layout } from '../components1/layout';
import LinedTitle from '../components1/LinedTitle';
import {Title, Text} from '../components1/Texts';
import Section from '../components1/Section';

const LinedTitleResponsive = styled(LinedTitle)`
    padding-top: 40px;
    @media only screen and (max-width: 599px) {
        display: none !important;
    }
`;

const TitleResponsive = styled(Title)`
    padding-top: 15px;
    font-size: 1.4rem;
    /* margin-left: 40px; */
    /* padding-bottom: 10px; */
    /* white-space: nowrap; */

    @media only screen and (min-width: 599px) {
        display: none !important;
    }
`;

const IndexPage = withTheme(({ theme, data }) => (
    <Layout pageName="mentions-legales">
        {
            ({ openConnectForm }) => (
                <>
                    <LinedTitleResponsive
                        borderColor={theme.color.beigeFaible}
                        borderWidth="1.5em"
                        style={{
                            paddingLeft: 0,
                            // marginTop: 80
                        }}
                    >
                        <Title>Mentions Légales</Title>
                    </LinedTitleResponsive>
                    <TitleResponsive
                        style={{
                            textAlign: 'center'
                        }}
                    >
                            Mentions Légales
                    </TitleResponsive>
                    <Section
                        style={{
                            paddingTop: 30,
                            paddingBottom: 30
                        }}
                    >
                        <Text
                            style={{
                                padding: '10px 30px',
                                // textAlign: 'justify',
                            }}
                            css="white-space: pre-line"
                        >
                            {
                                `
                                En application de l'article 19 de la Loi 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique

                                Propriétaire :
                                SARL CABADICI
                                74 rue des Lucques
                                34570 VAILHAUQUES
                                Tél : 07 56 87 34 12
                                SARL au capital de 2000 €
                                Immatriculation : 844 638 205 00014
                                RCS : Montpellier
                                Numéro de TVA Intracommunautaire : FR20844638205

                                Créateur : SARL CABADICI
                                Responsable de publication : Coline Mioch - contact@cabadici.fr
                                Webmaster : Fanny Nazon - contact@cabadici.fr
                                Hébergement : 1&1 Internet SARL
                                Crédit Photos : Anouck Ducros anouck@cabadici.fr
                                `
                            }
                        </Text>
                    </Section>
                </>
            )
        }
    </Layout>
));

IndexPage.propTypes = {
    theme: PropTypes.shape().isRequired
};

export default IndexPage;
